import React from 'react';
import { Link } from 'react-router-dom';
import { Shield, ArrowLeft, Book, Lock, UserCheck, Settings, AlertCircle } from 'lucide-react';

export default function PrivacyPolicy() {
  const sections = [
    {
      icon: <Lock className="w-12 h-12 text-blue-400" />,
      title: "Coleta de Dados",
      items: [
        "Informações de cadastro",
        "Dados de pagamento",
        "Informações de uso",
        "Cookies e tecnologias similares",
        "Logs do sistema"
      ]
    },
    {
      icon: <UserCheck className="w-12 h-12 text-blue-400" />,
      title: "Seus Direitos",
      items: [
        "Acesso aos dados",
        "Correção de informações",
        "Exclusão de dados",
        "Portabilidade",
        "Revogação de consentimento"
      ]
    },
    {
      icon: <Settings className="w-12 h-12 text-blue-400" />,
      title: "Proteção de Dados",
      items: [
        "Criptografia SSL/TLS",
        "Monitoramento contínuo",
        "Backups seguros",
        "Acesso controlado",
        "Auditorias regulares"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 via-indigo-950 to-slate-900">
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)]" />

      {/* Header Section */}
      <header className="relative container mx-auto px-4 pt-24 pb-16">
        <div className="text-center max-w-5xl mx-auto">
          {/* Back Button */}
          <Link to="/" className="inline-flex items-center text-indigo-300 hover:text-indigo-200 mb-8">
            <ArrowLeft className="w-5 h-5 mr-2" />
            Voltar para página inicial
          </Link>

          <br />

          {/* Title Badge */}
          <div className="inline-flex items-center bg-indigo-900/30 rounded-full px-6 py-2 text-indigo-200 mb-8">
            <Shield className="w-4 h-4 mr-2" />
            <span>Política de Privacidade</span>
          </div>

          <h1 className="text-4xl md:text-6xl font-bold mb-8">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-white via-blue-100 to-indigo-200">
              Política de Privacidade
            </span>
          </h1>

          <p className="text-xl text-gray-300 max-w-3xl mx-auto leading-relaxed mb-12">
            Entenda como coletamos, usamos e protegemos suas informações pessoais
          </p>
        </div>
      </header>

      {/* Main Content */}
      <main className="relative container mx-auto px-4 py-16">
        {/* Last Updated */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center bg-blue-900/30 rounded-full px-6 py-2 text-blue-300 text-sm">
            <Book className="w-4 h-4 mr-2" />
            <span>Última atualização: 19 de novembro de 2024</span>
          </div>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto mb-20">
          {sections.map((section, index) => (
            <div key={index} className="bg-gradient-to-br from-indigo-900/40 to-slate-900/40 p-8 rounded-2xl backdrop-blur-sm border border-indigo-800/20">
              <div className="mb-6">{section.icon}</div>
              <h3 className="text-2xl font-bold text-white mb-6">{section.title}</h3>
              <ul className="space-y-3">
                {section.items.map((item, i) => (
                  <li key={i} className="flex items-center gap-3 text-gray-300">
                    <div className="w-1.5 h-1.5 rounded-full bg-blue-400" />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Detailed Content */}
        <div className="max-w-4xl mx-auto prose prose-invert">
          <div className="bg-gradient-to-br from-indigo-900/40 to-slate-900/40 p-8 rounded-2xl backdrop-blur-sm border border-indigo-800/20">
            <h2 className="text-3xl font-bold text-white mb-6">1. Introdução</h2>
            <p className="text-gray-300 mb-8">
              Bem-vindo à Política de Privacidade do Guia de Negócios Digitais com IA. Este documento foi criado para demonstrar nosso compromisso com a proteção de seus dados pessoais e sua privacidade.
            </p>

            <h2 className="text-3xl font-bold text-white mb-6">2. Informações Coletadas</h2>
            <p className="text-gray-300 mb-4">Coletamos as seguintes informações:</p>
            <ul className="list-disc pl-6 text-gray-300 mb-8">
              <li>Nome completo</li>
              <li>Endereço de e-mail</li>
              <li>Informações de pagamento</li>
              <li>Dados de uso do site</li>
            </ul>

            <h2 className="text-3xl font-bold text-white mb-6">3. Uso das Informações</h2>
            <p className="text-gray-300 mb-8">
              Utilizamos suas informações para processar compras, fornecer acesso ao conteúdo, enviar atualizações importantes e melhorar nossos serviços.
            </p>

            <div className="flex items-center gap-3 bg-blue-900/30 p-4 rounded-xl mb-8">
              <AlertCircle className="w-6 h-6 text-blue-400 flex-shrink-0" />
              <p className="text-blue-200 text-sm">
                Não compartilhamos suas informações pessoais com terceiros para fins de marketing.
              </p>
            </div>

            <h2 className="text-3xl font-bold text-white mb-6">4. Contato</h2>
            <p className="text-gray-300">
              Para questões relacionadas à sua privacidade, entre em contato através do email: 
              <a href="mailto:carlos@balsalobre.dev" className="text-blue-400 hover:text-blue-300 ml-1">
                carlos@balsalobre.dev
              </a>
            </p>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-slate-900 text-gray-400 py-8 text-center text-sm border-t border-gray-800">
        <div className="container mx-auto px-4">
          <p>© 2024 Carlos Balsalobre. Todos os direitos reservados.</p>
        </div>
      </footer>
    </div>
  );
}